import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import ButtonSolid from "../Button/ButtonSolid";

const StyledCTA = styled(BackgroundImage)`
   ${tw`flex!`}
   ${({ backgroundImageAlternative }) => (backgroundImageAlternative ? tw`bg-gray-100` : null)};
   /* min-height: ${({ minHeightMobile }) => {
      return minHeightMobile;
   }}; */
   &:before,
   &:after {
      background-position: ${({ backgroundPositionMobile }) => {
         return backgroundPositionMobile;
      }} !important;
      background-size: ${({ backgroundSizeMobile }) => {
         return backgroundSizeMobile;
      }} !important;
   }
   @media (min-width: 768px) {
      /* min-height: ${({ minHeightDesktop }) => {
         return minHeightDesktop;
      }}; */
      &:before,
      &:after {
         background-position: ${({ backgroundPositionDesktop }) => {
            return backgroundPositionDesktop;
         }} !important;
         background-size: ${({ backgroundSizeDesktop }) => {
            return backgroundSizeDesktop;
         }} !important;
      }
   }
`;

const CTA = ({
   className,
   backgroundImageAlternative,
   backgroundPositionDesktop = "50% 50%",
   backgroundPositionMobile = "50% 50%",
   backgroundSizeDesktop = "cover",
   backgroundSizeMobile = "cover",
   minHeightDesktop = "707px",
   minHeightMobile = "614px",
   modalOpen="modal-contact"
}) => {
   const data = useStaticQuery(graphql`
      {
         ctaBgImageDesktop: file(relativePath: { eq: "repeating/cta/cta-bg-desktop.jpg" }) {
            childImageSharp {
               fixed(width: 2880, height: 1414) {
                  ...GatsbyImageSharpFixed_withWebp
               }
            }
         }
         ctaBgImageAlternativeDesktop: file(relativePath: { eq: "repeating/cta/cta-bg-desktop.jpg" }) {
            childImageSharp {
               fixed(width: 2880, height: 1414) {
                  ...GatsbyImageSharpFixed_withWebp
               }
            }
         }
         ctaBgImageMobile: file(relativePath: { eq: "repeating/cta/cta-bg-mobile.jpg" }) {
            childImageSharp {
               fixed(width: 750, height: 1228) {
                  ...GatsbyImageSharpFixed_withWebp
               }
            }
         }
         ctaBgImageAlternativeMobile: file(relativePath: { eq: "repeating/cta/cta-bg-mobile.jpg" }) {
            childImageSharp {
               fixed(width: 750, height: 1228) {
                  ...GatsbyImageSharpFixed_withWebp
               }
            }
         }
      }
   `);

   let backgroundImages;

   if (backgroundImageAlternative) {
      backgroundImages = [
         data.ctaBgImageAlternativeDesktop.childImageSharp.fixed,
         {
            ...data.ctaBgImageAlternativeMobile.childImageSharp.fixed,
            media: `(max-width: 767px)`,
         },
      ];
   } else {
      backgroundImages = [
         data.ctaBgImageDesktop.childImageSharp.fixed,
         {
            ...data.ctaBgImageMobile.childImageSharp.fixed,
            media: `(max-width: 767px)`,
         },
      ];
   }

   return (
      <StyledCTA
         className={`py-32 md:py-44 ${className}`}
         fixed={backgroundImages}
         backgroundImageAlternative={backgroundImageAlternative}
         minHeightDesktop={minHeightDesktop}
         minHeightMobile={minHeightMobile}
         backgroundSizeDesktop={backgroundSizeDesktop}
         backgroundSizeMobile={backgroundSizeMobile}
         backgroundPositionDesktop={backgroundPositionDesktop}
         backgroundPositionMobile={backgroundPositionMobile}
      >
         <div className="container">
            <div className="max-w-3xl mx-auto text-center">
               <h2 className="text-white mb-6">Explore the possibilities.</h2>
               <p className="text-white text-opacity-80 text-2xl md:text-2xl">
                  Want to see how we can bring your concept to life? Tell us about your needs and we’ll get you a quote!
               </p>
               <ButtonSolid as="button" data={modalOpen} text="Get a Quote" />
            </div>
         </div>
      </StyledCTA>
   );
};

export default CTA;
